import React from "react"

import TEDxKIContainer from '../components/tedxki-container'
import SponsorsDisplay from '../components/sponsors-display'
import ButtonRed from '../components/button-red'

import * as styles from './sponsors.module.css'

export default function HomePage() {

  return (
    <TEDxKIContainer>
      <div className={styles.tedxki_container}>
        <div>
          <h5>TEDxKI is supported by a generous community of partners.</h5>
          <p>
            We are able to present our great range of events, activities, and materials thanks to their support and commitment. We work with organisations that share our enthusiasm for progressive ideas and their potential to change the world.
          </p>
          <h4>We are currently seeking sponsors for our TEDxKI 2024 event. <br />
            Please contact us for more information!
          </h4>
          <div class="mt-4">
            <ButtonRed>
              <a href="mailto:financeteam@tedxki.com">Become a sponsor</a>
            </ButtonRed>
          </div>
        </div>
      </div>
      <SponsorsDisplay/>
    </TEDxKIContainer>
  );
};
